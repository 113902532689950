







































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import ServiceComments from '@/views/service/comments/ServiceComments.vue'
import ServiceCommentBlock from '@/views/service/comments/ServiceCommentBlock.vue'
import ServiceComment from '@/types/serviceComment'
import RelatedServiceItems from '@/views/service/RelatedServiceItems.vue'
import Issue, { IssueTypes } from '@/types/issue'
import moment from 'moment/moment'
import ServiceObjectInfo from '@/views/service/ServiceObjectInfo.vue'
import Filelist from '@/components/media/Filelist.vue'
import axios from 'axios'
import { ServiceClasses, ServiceStates, SortedStates, Status } from '@/types/serviceItem'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import ServiceDetails from '@/views/service/ServiceDetails.vue'
import ServiceContactInfo from '@/views/service/ServiceContactInfo.vue'
import ServiceBuildingSelector from '@/views/service/ServiceBuildingSelector.vue'
import SubscribeButton from '@/components/base/SubscribeButton.vue'
import PageHeader from '@/components/base/PageHeader.vue'
import PreviewContainer from '@/components/media/PreviewContainer.vue'
import SaveButton from '@/components/base/SaveButton.vue'
import SimpleUploader from '@/components/media/SimpleUploader.vue'
import { Base64MediaFile, getBase64Async } from '@/types/media'
import SolverSelector from '@/views/service/components/SolverSelector.vue'
import PrioritySelector from '@/views/service/components/PrioritySelector.vue'
import Tagger from '@/components/base/Tagger.vue'
import ServicePlanner from '@/views/service/components/ServicePlanner.vue'

@Component({
  components: {
    ServicePlanner,
    Tagger,
    PrioritySelector,
    SolverSelector,
    SimpleUploader,
    SaveButton,
    PreviewContainer,
    PageHeader,
    SubscribeButton,
    ServiceBuildingSelector,
    ServiceContactInfo,
    ServiceDetails,
    ServiceObjectInfo,
    ActivityLogTable,
    Filelist,
    IssueObjectInfo: ServiceObjectInfo,
    RelatedServiceItems,
    ServiceCommentBlock,
    ServiceComments,
    ServiceCreateComment: ServiceComments
  }
})

export default class IssueEdit extends Vue {
  serviceClasses = ServiceClasses
  serviceStates = ServiceStates
  sortedStates = SortedStates
  issueTypes = IssueTypes
  moment = moment
  config = {
    entity: 'issue',
    entities: 'issues'
  }

  settings = {
    routeNames: {
      list: 'issues',
      show: 'issueShow',
      edit: 'issueEdit'
    },
    routeParamKey: 'issueId',
    permissionNames: {
      show: 'issues.view',
      edit: 'issues.edit'
    },
    functionNames: {
      getFilters: 'getIssueFilters',
      getList: 'getIssueList',
      setCurrent: 'setCurrentIssue',
      clearCurrent: 'clearCurrentIssue',
      save: 'saveIssue'
    },
    getterNames: {
      current: 'currentIssue',
      loading: 'issueLoading',
      errors: 'issueErrors',
      list: 'list',
      filters: 'objectionFilters'
    }
  }

  downloadOptionsModalVisible = false
  exportTypes: string[] = []
  currentTab: string = new SecureLS({ isCompression: false }).get('issueEditActiveTab') || 'details'
  tabs = [
    {
      key: 'details',
      tab: 'Details'
    },
    {
      key: 'related',
      tab: 'Gerelateerd'
    },
    {
      key: 'activity',
      tab: 'Geschiedenis'
    }
  ]

  contact = {
    name: null,
    phone: null,
    email: null
  }

  search = {
    company: ''
  }

  downloading = false

  @Watch('currentItem.project.id')
  onProjectChanged (): void {
    if (this.currentItem.project.id === 0) return
    this.$store.dispatch('getIssueManagers', this.currentItem.project.id)
  }

  onTabChange (key: string): void {
    this.currentTab = key
  }

  mounted (): void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    this.$store.dispatch('getCompanies')
  }

  destroyed (): void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  reloadIssue (): void {
    this.mounted()
  }

  get canEdit (): boolean {
    return this.authUser.permissions.includes(this.settings.permissionNames.edit)
  }

  get canSeeTags (): boolean {
    return this.authUser.permissions.includes('issues.tags.view')
  }

  get canEditTags (): boolean {
    return this.authUser.permissions.includes('issues.tags.edit') && this.canEdit
  }

  get currentItem (): Issue {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get building (): Building {
    return this.currentItem.building || new Building()
  }

  get comments (): ServiceComment[] {
    return this.currentItem.comments || [new ServiceComment()]
  }

  get managers (): { id: number, active: boolean, full_name: string, role: string }[] {
    return this.$store.getters.issueManagers
  }

  save (): void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  saveAndReturn (): void {
    this.save()
    this.$router.push({ name: this.settings.routeNames.list })
  }

  onTagsUpdated (tags: string[]): void {
    this.$store.dispatch('setIssueTags', tags)
  }

  getStatus (): Status {
    return this.serviceClasses.find(s => s.id === this.currentItem.status) || this.serviceClasses[0]
  }

  onBuildingSelected (id: number): void {
    this.$store.dispatch('setIssueBuilding', id)
  }

  onProjectSelected (id: number): void {
    this.$store.dispatch('setIssueProject', id)
  }

  onManagerSelected (id: number): void {
    this.$store.dispatch('setIssueManager', id)
  }

  onCommentSaved (comment: ServiceComment): void {
    if (comment.waitingForResponse) {
      this.$store.dispatch('setIssueStatus', this.serviceStates.InAfwachting)
    }
  }

  previewFiles:Base64MediaFile[] = []

  async handleMedia (file: File): Promise<void> {
    const previewFile: Base64MediaFile = new Base64MediaFile(file)
    previewFile.url = await getBase64Async(file)
    previewFile.name = file.name.replace(/\.[^/.]+$/, '')
    this.previewFiles = [...[previewFile], ...this.previewFiles]
    await this.$store.dispatch('setIssueMedia', this.previewFiles)
  }

  deleteFile (target: { index: number, file: File}) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/issues/' + this.currentItem.id + '/media/' + this.currentItem.files[target.index].id)
    this.$store.dispatch('removeIssueFile', target.index)
  }

  generatePDF (): void {
    this.downloading = true
    const payload = {
      types: this.exportTypes
    }
    axios.post(`${process.env.VUE_APP_API_URL}/issues/${this.currentItem.id}/export`, payload, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Export_Servicemelding_' + this.currentItem.id + '.pdf'
        link.click()
      }).finally(() => {
        this.downloading = false
      })

    this.exportTypes = []
    this.downloadOptionsModalVisible = false
  }

  filterOptions (input: string, option: { componentOptions: { children: { text: string }[] } }): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get isRental (): boolean {
    const rentalStates: number[] = [5, 6, 7]
    return rentalStates.includes(this.currentItem.building?.state)
  }

  get authUser (): User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading (): boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving (): boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get errors (): { [key: string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }
}
